<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1>Edit Nav page</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" type="button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" type="button" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:1000px;">
            <el-form ref="pagesForm1" :model="Pages" :rules="PagesRules1" v-show="langName=='ua'">
                <div class="form-au" >
                    <h1>User Linkes</h1>
                    <div class="item mt-20">
                        <p>Accaunt</p>
                        <el-form-item prop="data.accaunt_ua">
                            <el-input v-model="Pages.data.accaunt_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>My Kerdit</p>
                        <el-form-item prop="data.credit_ua">
                            <el-input v-model="Pages.data.credit_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>History Payment</p>
                        <el-form-item prop="data.history_ua">
                            <el-input v-model="Pages.data.history_ua"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Message</p>
                        <el-form-item prop="data.message_ua">
                            <el-input v-model="Pages.data.message_ua"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Log Out</p>
                        <el-form-item prop="data.logout_ua">
                            <el-input v-model="Pages.data.logout_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Log In</p>
                        <el-form-item prop="data.login_ua">
                            <el-input v-model="Pages.data.login_ua"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Settings</h1>
                    <div class="item">
                        <p>Name</p>
                        <el-form-item prop="data.set_name_ua">
                            <el-input v-model="Pages.data.set_name_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Title</p>
                        <el-form-item prop="data.set_title_ua">
                            <el-input v-model="Pages.data.set_title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Submit</p>
                        <el-form-item prop="data.set_submit_ua">
                            <el-input v-model="Pages.data.set_submit_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Reset</p>
                        <el-form-item prop="data.set_reset_ua">
                            <el-input v-model="Pages.data.set_reset_ua"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Information</h1>
                    <div class="item">
                        <p>Title</p>
                        <el-form-item prop="data.information_ua">
                            <el-input v-model="Pages.data.information_ua"></el-input>
                        </el-form-item>
                    </div>  
                    <div class="item">
                        <el-form-item prop="files.logo_ua">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Upload Logo
                                    <input type="file" name="image" @change="UploadIMage($event ,'logo_ua')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.logo_ua&&Pages.files.logo_ua.name? Pages.files.logo_ua.name: Pages.files.logo_ua ? Pages.files.logo_ua :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>

                </div>
            </el-form>
            <el-form ref="pagesForm2" :model="Pages" :rules="PagesRules2" v-show="langName=='en'">
                 <div class="form-au" >
                    <h1>User Linkes</h1>
                    <div class="item mt-20">
                        <p>Accaunt</p>
                        <el-form-item prop="data.accaunt_en">
                            <el-input v-model="Pages.data.accaunt_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>My Kerdit</p>
                        <el-form-item prop="data.credit_en">
                            <el-input v-model="Pages.data.credit_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>History Payment</p>
                        <el-form-item prop="data.history_en">
                            <el-input v-model="Pages.data.history_en"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Message</p>
                        <el-form-item prop="data.message_en">
                            <el-input v-model="Pages.data.message_en"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Log Out</p>
                        <el-form-item prop="data.logout_en">
                            <el-input v-model="Pages.data.logout_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Log In</p>
                        <el-form-item prop="data.login_en">
                            <el-input v-model="Pages.data.login_en"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Settings</h1>
                    <div class="item">
                        <p>Name</p>
                        <el-form-item prop="data.set_name_en">
                            <el-input v-model="Pages.data.set_name_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Title</p>
                        <el-form-item prop="data.set_title_en">
                            <el-input v-model="Pages.data.set_title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Submit</p>
                        <el-form-item prop="data.set_submit_en">
                            <el-input v-model="Pages.data.set_submit_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Reset</p>
                        <el-form-item prop="data.set_reset_en">
                            <el-input v-model="Pages.data.set_reset_en"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Information</h1>
                    <div class="item">
                        <p>Title</p>
                        <el-form-item prop="data.information_en">
                            <el-input v-model="Pages.data.information_en"></el-input>
                        </el-form-item>
                    </div>  
                    <div class="item">
                        <el-form-item prop="files.logo_en">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Upload Logo
                                    <input type="file" name="image" @change="UploadIMage($event ,'logo_en')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.logo_en&&Pages.files.logo_en.name? Pages.files.logo_en.name: Pages.files.logo_en ? Pages.files.logo_en :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>

                </div>
            </el-form>
                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin/statpages')"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="PostStock()"> Edit</button>
                </div> 
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            checked: false,
            langName: 'ua',
            fileList: [],
            value1: '',
            form: {
                name: ''
            },
            loading: false,
            erros: {
                image: false,
                en: false,
                ua: false
            },
            Pages: {
                key: "/nav",
                data: {
                    accaunt_ua:'',
                    accaunt_en:'',
                    credit_ua:'',
                    credit_en:'',
                    history_ua:'',
                    history_en:'',
                    message_ua:'',
                    message_en:'',
                    logout_ua:'',
                    logout_en:'',
                    set_name_ua:'', 
                    set_name_en:'', 
                    set_title_ua:'',
                    set_title_en:'',
                    set_submit_ua:'',
                    set_submit_en:'',
                    set_reset_ua:'',
                    set_reset_en:'',
                    information_ua:'',
                    information_en:'',
                    login_ua:'',
                    login_en:'',


                },
                files: {
                    logo_ua: '',
                    logo_en: '',
                }
            },
            PagesRules1: {         
                data: {
                    accaunt_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    credit_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    history_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    message_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    logout_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    set_title_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    set_submit_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    set_reset_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    information_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    login_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    set_name_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],


                },
                files: {
                    logo_ua:   [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                }
            },
            PagesRules2:{
               data: { 
                    accaunt_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    credit_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    history_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    message_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    logout_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    set_title_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    set_submit_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    set_reset_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],  
                    information_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    login_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    set_name_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],


                },
                files: { 
                    logo_en: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                }
            }
        }

    },

    methods: {
        UploadIMage(event, name) {
            this.Pages.files[`${name}`] = event.target.files[0]
        },
        PostStock() {

            this.$refs.pagesForm1.validate((valid1) => {
                if (valid1) {
                    this.$refs.pagesForm2.validate((valid2) => {
                        if(valid2){
                            this.loading = true
                        let files = new FormData();
                        var pageData = {}
                        if (typeof this.Pages.data != 'string') {
                            pageData = JSON.stringify(this.Pages.data)
                        }
                        files.append('key', this.Pages.key)
                        files.append('data', pageData)
                        for (let i of Object.keys(this.Pages.files)) {
                            if (this.Pages.files[i].name) {
                                files.append(`files[${i}]`, this.Pages.files[i]);
                            }
                        }

                        this.axios.post(this.Pages.id ? `/admin/statpage/${this.Pages.id}` : '/admin/statpage', files).then(res => {
                                console.log(res)
                                this.$router.push('/admin/statpages')
                                this.$notify({
                                    title: 'Успешный',
                                    message: 'Информация успешно сохранена',
                                    type: 'success'
                                });
                                this.$store.dispatch("get_statpages");
                            })
                            .catch(error => {
                                this.erorrsData = error.response.data.message
                                let ERRORS = error.response.data.errors;
                                let keys = Object.keys(ERRORS);
                                let error_msg = ERRORS[keys[0]];
                                console.log(error_msg[0])
                                this.$notify.error({
                                    title: 'Произошла ошибка',
                                    message: error_msg ? error_msg[0] : "Произошла ошибка",
                                });

                            })
                            .finally(() => {
                                this.loading = false
                            })
                        }
                        else{
                            this.langName='en'
                        }
                        
                    })
                }
                else{
                    this.langName='ua'
                } 
                
                 
            })

        },
        GetPages() {
            this.loading = true
            this.axios.get('/admin/statpage')
                .then(res => {
                    var data = res.data.data
                    var home = data.filter(a => {
                        return a.key === '/nav'
                    })
                    if (home[0]) {
                        this.Pages.id = home[0].id
                        this.Pages.key = home[0].key
                        this.Pages.data = home[0].data
                        for (let i of Object.keys(home[0].files)) {
                            this.Pages.files[i] = home[0].files[i]
                        }

                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }

    },
    mounted() {
        this.GetPages()
    }
}
</script>

<style lang="scss" scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}

.upload-image {
    width: 100%;
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
    }

    button {
        width: 200px;
        position: relative;
        overflow: hidden;
        padding: 10px 15px;
        border-radius: 5px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        border: none;
        color: var(--black);

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
        }
    }
}

.admin-h2-title {
    margin: 20px 0px 10px 0px;
    font-family: Stem-Medium;
    color: var(--other-black);
}

.error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.c-red {
    color: red !important;
}

.add-inputs {
    h1 {
        margin: 20px 0px;
        font-size: 24px;
    }
}
</style>
